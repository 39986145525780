import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"

export default function Template({ data }) {
  const article = data.markdownRemark

  return (
    <>
      <Seo title={article.frontmatter.title} />
      <h1>{article.frontmatter.title}</h1>
      <hr />
      <small>Published {article.frontmatter.date}</small>
      <div
        dangerouslySetInnerHTML={{ __html: article.html }}
        className="articlePage"
      />
    </>
  )
}

export const articleQuery = graphql`
  query ArticleByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date
        title
      }
    }
  }
`
